import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Button,
  Container,
  TextField,
  CssBaseline,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab,
  CardContent
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  left_flex_box
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { downloadProductsQRS, getProducts } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";

export const PrductsList = ({
  getProducts,
  user,
  products,
  warehouseId,
  downloadProductsQRS,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [copies, setCopies] = React.useState(1);
  const currentLocale = Cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts({ page: 1, limit: 10000, warehouseId })
  }, [])

  const columns = [
    {
      title: 'Product SKU',
      dataIndex: '_id',
      filters: [
        ...new Map(
          products?.docs
            ?.map((product) => [
              product.sku, // Key to ensure uniqueness
              { text: product.sku, value: product.sku },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record._id?.startsWith(value),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      filters: products?.docs?.map((product) => {
        return {
          text: product.name,
          value: product.name
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      filters: [
        ...new Map(
          products?.docs
            ?.filter((product) => {return product.size}).map((product) => [
              product.size, // Key to ensure uniqueness
              { text: product.size, value: product.size },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.size?.startsWith(value),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (price, _) => `${price} EGP`
    },
    (window.location.pathname.includes("warehouse") ? {
      title: 'QTY On Hand',
      dataIndex: 'amount',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.amount - b.amount,
    }: {}),
    (window.location.pathname.includes("warehouse") ? {
      title: 'Inbound',
      dataIndex: 'inbound',
      sorter: (a, b) => a.inbound - b.inbound,
    } : {}),
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      filters: [
        ...new Map(
          products?.docs
            ?.filter((product) => product.vendor !== null)
            .map((product) => [
              product.vendor?.name, // Use the vendor's name as the key
              { text: product.vendor?.name, value: product.vendor?.name },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.vendor.startsWith(value),
    },
  ];

  const data = products?.docs?.map((row) => {
    return {
      key: row._id,
      _id: row.sku || row.externalId,
      image: row.image,
      name: row.name,
      size: row.size,
      color: row.color,
      weight: row.weight,
      price: row.price,
      vendor: row.vendor.name,
      product: row,
      amount: row.stocks?.reduce((a, b) => a + b.amount, 0),
      inbounds: row.inbounds,
      inbound: row.inbounds?.reduce((a, b) => a + (b.quantity - b.quantityReceived), 0),
    }
  })

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedProducts(newSelectedRowKeys.map((key) => { return key }));
  };

  const rowSelection = {
    selectedProducts,
    onChange: onSelectChange,
  };

  const handlePrintSelected = () => {
    const data = products?.docs?.filter((row) => { return selectedProducts?.includes(row._id) }).map((row) => {
      return {
        quantity: copies,
        product: row,
        vendor: row.vendor,
      }
    })

    downloadProductsQRS({ items: data })
  }


  const handlePrintAllCopies = () => {
    const data = products?.docs?.map((row) => {
      return {
        quantity: copies,
        product: row,
        vendor: row.vendor,
      }
    })

    downloadProductsQRS({ items: data })
  }

  return (
    <div>
      <Box sx={{ width: "100%", px: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Products</Typography>
        </Box>
        <CardContent>
          <Box sx={{ ...left_flex_box }}>
            <MainInput
              sx={{ marginInlineEnd: 2, minWidth: "20vw" }}
              defaultValue={copies}
              label="Copies to Print"
              onChange={({ target }) => setCopies((target.value))}
              type="number"
              inputProps={{
                min: 1
              }}
            />
            <Button onClick={() => handlePrintSelected()} disabled={selectedProducts?.length === 0 || isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print ${copies} copies of selected products`}</Button>
            <Button onClick={() => handlePrintAllCopies()} disabled={isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print ${copies} copies of all products`}</Button>
            {/* <Button onClick={() => handlePrintAllSelected()} disabled={selectedProducts?.length === 0 || isLoading} sx={{ ...main_button, mr: 1 }}>{isLoading ? <CircularProgress color="white" /> : `Print actual quantities of selected items`}</Button> */}
          </Box>
          <Typography sx={{ ml: 1 }}>{selectedProducts?.length > 0 ? `Selected ${selectedProducts?.length} items` : null}</Typography>
        </CardContent>
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  products: state?.records?.products
});

const mapDispatchToProps = { getProducts, downloadProductsQRS };

export default connect(mapStateToProps, mapDispatchToProps)(PrductsList);
